<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="项目列表" />
		<el-button class="add-btn" @click="handleAdd">发布</el-button>
		<el-table :data="tableData" border>
			<el-table-column fixed prop="title" label="标题" />
			<el-table-column label="封面图">
				<template slot-scope="scope">
					<el-image class="table-img" :src="$store.state.IMG_URL+'/'+scope.row.photo">
						<div slot="error" class="image-slot">
							<span>无封面图</span>
						</div>
					</el-image>
				</template>
			</el-table-column>
			<el-table-column label="日期" prop="time" />
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="handleJump(scope.row)">查看</el-button>
					<el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
					<el-popconfirm title="确认删除吗？" @confirm="handleDel(scope.row)">
						<el-button type="text" size="small" slot="reference">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: []
			}
		},
		beforeCreate(){
			// this.$axios.post('/getsellerinfo').then(res => {
			// 	if(res.level == 2  || res.level  == 3  || res.level == 5){
			// 		location.href= '/#/cases'
			// 	}else{
			// 		location.href= '/#/miss'
			// 	}
			// })
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				this.$axios.post('/getgcallist').then(res => {
					this.tableData = res.list
				})
			},
			handleJump(row) {
				open(`${this.$store.state.ROOT_URL}/gcinfo/${this.$store.state.USER_ID}/${row.id}.html`)
			},
			handleDel(row) {
				this.$axios.post('gcaltatus', {
					id: row.id
				}).then(res => {
					this.$message({
						type: 'success',
						message: '删除成功'
					})
					this.getData()
				})
			},
			handleEdit(row) {
				this.$router.push(`/cases/revise?id=${row.id}`)
			},
			handleAdd() {
				this.$router.push('/cases/release')
			}
		}
	}
</script>

<style scoped>
	.add-btn{
		margin:10px 0 20px 20px ;
	}
	.o-wrap{
		width: 1600px;
		background-color: #fff;
	}
	.o-wrap>>>tr .cell {
		text-align: center;
	}

	.table-img {
		width: 100px;
		height: 100px;
	}

	.el-button+span {
		margin-left: 10px;
	}

	.o-wrap>>>.image-slot {
		width: 90px;
		height: 90px;
		color: #c0c4cc;
		background: #f5f7fa;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
